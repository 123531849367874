body.sfPageEditor {
    .mobile-features-nudge {
        display: block !important;
        position: static !important;

        .mobile-show-features {
            display: block !important;
            height: auto !important;
        }
    }
}