.ticket-sponsor {
    $logo_height: 24px;
    background-color: $primary;
    color: #FFF;
    text-align: center;

    h2, h3, h4, h5 {
        margin: 0;
        color: #FFF;
        font-size: 1.5rem;
        padding: 20px 0;

        &, * {
            line-height: $logo_height;
            vertical-align: top;
        }

        a {
            color: #FFF;
            font-family: $play;
            position: relative;

            &:first-child {
                &:after, &:before {
                    content: '';
                    height: 2px;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    background-color: rgba(#FFF,0.4);
                    transition: right 0.3s ease;
                }

                &:before {
                    right: 100%;
                }

                &:after {
                    right: 0;
                }

                &:hover {
                    border-bottom-color: #FFF;
                    color: #FFF;

                    &:before {
                        right: 0;
                    }
                }
            }
        }

        img {
            height: $logo_height;
            padding-left: 5px;
            display: inline-block;
        }

        em {
            font-weight: 100;
            font-size: 0.7em;
            font-style: normal;
            padding-left: 10px;
            position: relative;
            //top: -3px;

            a {
                border: none;
            }
        }
    }

    &.ticket-discount {
        text-align: right;
    }

    &.ticket-discount-sponsor {
        h2, h3, h4, h5 {

        }
    }

    @media #{$small-only} {
        display: none;
    }
}

.homepage-rotator + .ticket-sponsor {
    margin: 0 0 20px;
}