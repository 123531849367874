.alert-bar {
    width: 100%;
    background-color: $primary;
    text-align: center;
    padding: 10px 20px;
    line-height: 1.2rem;

    a {
        color: $white !important;
        text-transform: uppercase;
        font-weight: bold;
        font-family: $raleway;
    }
}

.top-container {
    .top-bar {
        background: $white;
        position: relative;

        .grey-bkgd {
            position: absolute;
            top: 155px;
            height: 40px;
            width: 100%;
            z-index: 2;
        }

        #nav-ticket-link {
            position: absolute;
            top: 155px;
            z-index: 10;
            height: 40px;
            background-color: $secondary;
            padding-right: 10px;

            a {
                color: $white;
                text-transform: uppercase;
                font-family: $raleway;
                font-size: 1.125rem;
                font-weight: 700;

                img {
                    padding-right: 10px;
                    padding-bottom: 10px;
                }
            }

            &::after {
                content: '';
                position: absolute;
                left: 100%;
                top: 0%;
                width: 0;
                height: 0;
                border: 0 solid transparent;
                border-bottom-width: 21px;
                border-top-width: 19px;
                border-left: 12px solid $secondary;
            }
        }

        .top-bar-section {
            position: relative;
            max-width: 66.75rem;
            margin: 0 auto;
            width: auto;
            -moz-transition: left 300ms ease-out;
            -o-transition: left 300ms ease-out;
            -webkit-transition: left 300ms ease-out;
            transition: left 300ms ease-out;

            ul.sflanguagesVerticalList {
                float: right;

                li {
                    background-color: $white;

                    a {
                        background-color: $white;
                        padding: 0px;
                        margin: 0px;
                        color: $primary;
                        font-size: 15px;
                        text-transform: uppercase;
                        font-family: $raleway;

                        &:hover, &:focus, &:active {
                            color: $primary;
                            background-color: $white;
                        }
                    }
                }
            }



            ul.main-nav {
                width: 100%;
                line-height: 40px;
                position: absolute;
                top: 155px;
                z-index: 3;
                background: $lightGrey;
                -webkit-box-shadow: 0 6px 10px -10px rgba($black,0.3);
                -moz-box-shadow: 0 6px 10px -10px rgba($black,0.3);
                box-shadow: 0 6px 10px -10px rgba($black,0.3);
                left: 25px;
                padding-left: 170px;
                padding-right: 0;

                li {
                    border-right: 1px solid scale-color($lightGrey, $lightness: -10%);
                    background: none;

                    a {
                        height: 40px;
                        line-height: 40px;
                        background: $lightGrey;
                        color: $dark-grey;
                        padding: 0 10px;
                        font-size: 12px;
                        letter-spacing: -1px;
                        text-transform: uppercase;
                        font-family: $raleway;


                        &:hover, &:focus, &:active {
                            background: scale-color($primary, $lightness: -10%);
                            color: $white;
                        }

                        &.selected {
                            border-bottom: 3px solid $primary;
                        }
                    }

                    &.has-dropdown > a {
                        padding-right: 25px !important;
                    }

                    &.has-dropdown > a:after {
                        border-color: rgba($grey, 0.2) transparent transparent transparent;
                        margin-right: 10px;
                        margin-top: -3px;
                    }


                    .dropdown li {
                        border: none;

                        a:not(.button) {
                            background: scale-color($lightGrey, $lightness: -5%);
                            color: $dark-grey;

                            &:hover, &:focus, &:active {
                                background: scale-color($primary, $lightness: -10%);
                                color: $white;
                            }

                            &.selected {
                                border-bottom: none;
                                color: $primary;
                            }
                        }
                    }

                    &:last-child {
                        border: none;
                    }

                    &.buy-tickets {
                        border: 1px solid $white;
                        border-width: 0 2px 0 2px;
                        float: right;

                        a {
                            background: $primary;
                            color: $white;
                            position: relative;
                            padding: 0 15px;
                            font-size: 14px;

                            .left-tri::after {
                                content: "";
                                display: block;
                                border-top: 10px solid $primary;
                                border-right: 63px solid transparent;
                                position: absolute;
                                left: 0;
                                bottom: -10px;
                                z-index: 2;
                            }

                            .left-tri::before {
                                content: "";
                                display: block;
                                border-top: 12px solid $white;
                                border-right: 73px solid transparent;
                                position: absolute;
                                left: -2px;
                                bottom: -12px;
                                z-index: 1;
                            }

                            .right-tri::after {
                                content: "";
                                display: block;
                                border-top: 10px solid $primary;
                                border-left: 63px solid transparent;
                                position: absolute;
                                right: 0;
                                bottom: -10px;
                                z-index: 2;
                            }

                            .right-tri::before {
                                content: "";
                                display: block;
                                border-top: 12px solid $white;
                                border-left: 73px solid transparent;
                                position: absolute;
                                right: -2px;
                                bottom: -12px;
                                z-index: 1;
                            }

                            &:hover, &:focus, &:active {
                                background: $medPrimary;

                                .left-tri::after, .right-tri::after {
                                    border-top-color: $medPrimary;
                                }
                            }
                        }
                    }

                    &.search {
                        border: none;

                        a {
                            font-size: 16px;
                            font-weight: normal;
                        }
                    }
                }
            }

            a.buy-tickets.mobile {
                display: none !important;
                height: 55px;
                margin: 10px 0;
                padding: 0 15px;
                line-height: 55px;
                background: $primary;
                color: $white;
                float: right;
                text-transform: uppercase;
                font-size: 14px;

                &:hover, &:active, &:focus {
                    background: $medPrimary;
                    color: $white;
                }
            }

            .logo {
                width: 204px;
                position: absolute;
                margin: 0;
                z-index: 4;
                left: 0;
                padding-top: 5px;

                img {
                    height: 145px;
                    width: auto;
                }

                .contact-info {
                    color: $white;
                    padding: .75rem 0;

                    p {
                        margin: 0;
                        font-size: 14px;
                        color: $white;
                        line-height: 14px;
                    }

                    a {
                        color: $white;
                    }
                }

                .social-links {
                    line-height: 20px;

                    a {
                        color: $white;
                        font-size: 20px;
                        padding: 0 5px 0 0;
                    }
                }
            }

            .event-info {
                position: absolute;
                left: 180px;
                top: 60px;
                z-index: 4;
                width: 400px;

                p {
                    margin: 0;
                    color: $dark-grey;
                    text-transform: uppercase;
                    font-family: $raleway;
                    font-weight: 600;

                    &:first-child {
                        font-weight: 700;
                        font-size: 1.45rem;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    .black-bar {
        background: $darkGrey;
        text-align: center;
        color: $white;
        position: relative;
        padding: 1rem 3rem;

        .fa {
            color: $white;
            position: absolute;
            top: 10px;
            right: 10px;

            &:hover {
                color: scale-color($white, $lightness: -10%);
            }
        }
    }

    .top-ad-space {
        background: $lightGrey;
        padding: 1rem 1rem 14px;

        img {
            margin: 0 auto;
            display: block;
        }
    }

    @media #{$small-up} {
        .black-bar {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 9;
            overflow: hidden;
        }

        .top-bar {
            height: 75px;
            overflow: visible;

            .top-bar-section {
                padding: 0;
                height: 75px;

                .logo {
                    left: 5px;

                    a {
                        .logo-text {
                            display: none;
                        }
                    }

                    .social-links a {
                        padding: 0 5px 0 0;
                    }
                }
            }

            .grey-bkgd {
                display: none;
            }

            .sflanguagesVerticalList {
                li, a, span {
                    font-size: 13px;
                }
            }

            .sflanguagesVerticalList {
                margin: 15px;
            }
        }

        &.is-fixed {

            .top-bar {
                position: fixed;
                width: 100%;
                left: 0;
                z-index: 9;
                box-shadow: 0 3px 5px 0 rgba($darkGrey,0.35);

                .top-bar-section {
                    ul.main-nav {
                        left: initial;
                        right: 3rem;
                        width: auto;
                        padding: 0;
                        left: 25px;
                        padding-left: 155px;
                        right: 0;
                        padding-left: 20px;

                        li.buy-tickets {
                            float: left;

                            a {
                                .left-tri, .right-tri {
                                    overflow: hidden;

                                    &:after, &:before {
                                        border-top: 0;
                                        -moz-transition: height 0.2s;
                                        -o-transition: height 0.2s;
                                        -webkit-transition: height 0.2s;
                                        transition: height 0.2s;
                                    }
                                }
                            }
                        }
                    }

                    .logo {
                        width: 204px;
                        height: 145px;
                        padding-top: 5px;

                        .logo-text {
                            display: none;
                        }

                        &:after {
                            display: none;
                        }

                        img {
                            height: 145px;
                            width: auto;
                        }

                        .sfimageWrp {
                            height: auto;
                            opacity: 1;
                        }

                        .contact-info, .social-links {
                            height: 0;
                            overflow: hidden;
                            padding: 0;
                        }
                    }

                    .event-info {
                        left: 180px;
                    }
                }
            }

            .grey-bkgd {
                box-shadow: 0 3px 5px 0 rgba($darkGrey,0.35);
            }

            &.black-hidden {
                .top-bar-section ul.main-nav, .grey-bkgd, .logo, .top-bar {
                    top: 0 !important;
                }
            }
        }
    }

    @media #{$small-only} {
        .top-alert {
            .top-social-area {
                ul#social-media-area {
                    li.icon {
                        width: 25px;
                    }
                }
            }
        }


        .top-bar {
            #nav-ticket-link {
                top: 116px;
                left: 0;

                img {
                    padding-left: 10px;
                }
            }

            .top-bar-section {
                .logo {
                    padding: 5px;

                    img {
                        height: 100px;
                    }

                    .contact-info, .social-links {
                        display: none;
                    }
                }

                .event-info {
                    left: 120px;
                    top: 55px;
                    width: auto;
                    padding-right: 0;

                    p {
                        font-size: 11px;
                        line-height: 1.5em;
                        margin: 0;
                        color: $primary;
                        text-transform: uppercase;
                    }
                }
            }

            .sflanguagesVerticalList {
                width: initial;
                margin-top: 15px;
                position: absolute;
                top: -40px;
                right: 0;
                display: block;

                li, a, span {
                    background-color: transparent !important;
                }
            }
        }

        .black-bar {
            font-size: .75rem;
            line-height: .9rem;
        }
    }

    @media #{$medium-up} {
        .top-bar {
            .top-bar-section {
                .logo {
                    .contact-info, .social-links {
                        display: block;
                    }
                }

                .event-info {
                    p {
                        font-size: 13px;
                    }
                }
            }
        }
    }

    @media #{$medium-only} {
        .top-bar {
            .grey-bkgd {
                display: block;
                top: 112px;
            }

            #nav-ticket-link {
                top: 114px;
                left: 0;

                img {
                    padding-left: 10px;
                }
            }

            .top-bar-section {
                .logo {
                    .social-links {
                        a {
                            padding: 0 5px 0 0;
                        }
                    }
                }

                ul.main-nav {
                    li {
                        a {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }

    @media #{$large-up} {
        .top-bar {
            height: 195px;

            .top-bar-section {
                padding: 0;
                height: 195px;

                .logo {
                    left: 0;
                }

                .event-info {
                    p {
                        font-size: 15px;
                        line-height: 25px;
                        font-weight: 400;
                    }
                }
            }

            .grey-bkgd {
                display: block;
            }

            .sflanguagesVerticalList {
                margin: 0;

                li, a, span {
                    font-size: 15px;
                }
            }
        }

        .black-bar {
            line-height: 1rem;
            font-size: .8rem;
        }

        &.is-fixed {
            .white-bkgd {
                position: fixed;
                width: 100%;
                left: 0;
                z-index: 9;
                background-color: #fff;
                height: 195px;
                top: 0px;
            }

            .top-bar {
                position: relative !important;
                top: -20px;
                z-index: 8;
                background-color: #fff !important;
                height: 100px;

                .secondary-bkgd {
                    position: fixed;
                    /*top: 0;*/
                    z-index: 9;
                }

                .top-bar-section {
                    position: fixed;
                    right: 0;
                    z-index: 9;


                    ul.main-nav {
                        padding-left: 175px;
                        /*width:100%;*/
                        right: 0px;
                        left: 20px;
                    }
                    /*#nav-ticket-link {
                        position: fixed;
                        top: 0;
                    }

                    .logo {
                        border: none;
                        text-indent: 0;
                        z-index: 9;
                        position: absolute;
                        width: auto;
                        background: none;
                        padding: 0;
                        height: 30px;
                        line-height: 30px;
                        font-size: 12px;
                        text-transform: uppercase;
                        top: 0;

                        .logo-text {
                            display: block;
                        }

                        .sfimageWrp {
                            height: 0;
                            opacity: 0;
                            overflow: hidden;
                        }

                        .contact-info {
                            height: 0;
                            overflow: hidden;
                            padding: 0;
                        }

                        .social-links {
                            height: 0;
                            overflow: hidden;
                            padding: 0;
                        }
                    }

                    .event-info, .right {
                        display: none;
                    }

                    ul.main-nav {
                        top: 0;
                        right: 0;

                        li.buy-tickets {
                            float: left;

                            a {
                                .left-tri, .right-tri {
                                    overflow: hidden;

                                    &:after, &:before {
                                        border-top: 0;
                                        -moz-transition: height 0.2s;
                                        -o-transition: height 0.2s;
                                        -webkit-transition: height 0.2s;
                                        transition: height 0.2s;
                                    }
                                }
                            }
                        }
                    }*/
                }

                .grey-bkgd {
                    position: fixed;
                    width: 100%;
                    left: 0;
                    z-index: 9;
                    box-shadow: 0 3px 5px 0 rgba($darkGrey,0.35);
                }

                .sflanguagesVerticalList {
                    display: none;
                }
            }

            &.black-hidden {
                .top-bar-section, .grey-bkgd {
                    top: 0 !important;
                }
            }
        }
    }
}
@-moz-document url-prefix() {
    .top-container .top-bar .top-bar-section ul.main-nav {
        width: 100%;
        line-height: 40px;
        position: absolute;
        top: 155px;
        z-index: 3;
        background: $lightGrey;
        -webkit-box-shadow: 0 6px 10px -10px rgba($black,0.3);
        -moz-box-shadow: 0 6px 10px -10px rgba($black,0.3);
        box-shadow: 0 6px 10px -10px rgba($black,0.3);
        left: 25px;
        padding-left: 170px;
        right: 0;
    }

    .top-container.is-fixed .top-bar .top-bar-section ul.main-nav{
        left: 20px;
    }
}
@media screen and (max-width: 1100px){
    .top-container .top-bar .top-bar-section ul.main-nav li a{
        font-size: 11px;
    }
}