.listings-nav {
    h1 {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    a {
        display: block;
        margin-top: 1rem;
        color: #000;
    }

    .categories {
        padding-bottom: 2rem;
    }
}

.primary-content .category h2 {
    text-align:center; 
    width:100%;
    font-weight:400;
}

.listings {
    margin: 2rem 0;

    h1, .h1 {
        font-weight: 400;
        margin: 0;
        font-size: 1.4rem;
        margin-bottom: 2rem;
    }

    select {
        background-color: #eee;
        border: 0;
        font-size: .8rem;
        padding: .5rem;
        width: 100%;
        margin-bottom: 1.5rem;
        text-transform: uppercase;
    }

    .share-hearts, .view-my-hearts, .view-all-hearts {
        float: right;
        font-size: .8rem;
        color: $primary;
        margin-left: 1rem;

        span {
            text-transform: uppercase;
        }
    }

    .share-hearts:hover, .view-my-hearts:hover, .view-all-hearts:hover {
        cursor: pointer;

        span {
            text-decoration: underline;
        }
    }

    .share-hearts-modal {
        background-color: #eee;
        padding: 2.5rem;
        margin-bottom: 1rem;

        input[type=text] {
            width: 100%;
            margin-bottom: 1rem;
            margin-top: .5rem;
        }

        .button {
            width: 100%;
            text-align: center;
        }

        .button:hover {
            cursor: pointer;
        }

        .newsletter-opt-in {
            margin-bottom: 1rem;

            label {
                display: inline;
            }
        }
    }
}
.heart {
    color: $primary;
    position: absolute;
    right: 2.5rem;
    top: 1.1rem;
    font-size: 1.5rem;
}

.heart:hover {
    cursor: pointer;
}

#promotion-filter-select {
    background-color: #eee;
    border: 0;
    font-size: .8rem;
    padding: .5rem;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.promotion {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: .9rem;
    color: #000;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;

    h2 {
        margin: 0 0 1rem 0;
        font-weight: 400;
        font-size: 1.2rem;
        text-align: left;
        color:$primary;
    }

    p{
        line-height:1.2;
    }

    .button {
        margin-bottom: 0px;
        width: 100%;
        margin-top: .5rem;
    }
}

.promotion:hover {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    text-decoration: none;
}

.owl-dots {
    text-align: center;
    display: block;
    padding-bottom: 2rem;

    .owl-dot {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 3rem;
        background-color: #fff;
        margin: 0 .25rem;
    }

    .active {
        background-color: $primary;
    }
}

.sub-categories {
    margin-left: 1rem;
    padding: .5rem;
}

.listing-list {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
    padding: 1rem;
    min-height: 500px;
    margin-bottom: 2rem;
    font-size: .9rem;
    text-transform: uppercase;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    text-align: center;

    h2 {
        margin: 1rem 0;
        font-weight: 400;
        font-size: 1.2rem;
        min-height: 80px;

        a {
            color: #000;
            line-height: 24px;
            font-size: 18px;
        }

        a:hover {
            color: $primary;
        }
    }

    .button {
        width: 100%;
        text-align: center;
        margin: 1rem 0;
    }

    .button:hover {
        color: #fff;
    }

    .icons {
        margin-top: .5rem;

        img {
            margin-bottom: .5rem;
            width: auto;
            height: auto;
        }
    }
}

.listings-list:hover {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    text-decoration: none;
}

.promotions {
    .promotion-container {
        margin: .5rem;

        .promotion {
            background-color: #fff;
            display: inline-block;
            margin-top: 0;
            width:100%;

            .button{
                margin-top:1rem;
                width:100%;
                margin-bottom:0;
            }
        }
    }

    .owl-prev span {
        position: absolute;
        left: -4rem;
        top: 6rem;
        font-size: 6rem !important;
        color: #a6a1a1;
    }

    .owl-next span {
        position: absolute;
        right: -4rem;
        top: 6rem;
        font-size: 6rem !important;
        color: #a6a1a1;
    }

    .owl-prev span:hover, .owl-next span:hover {
        color: $primary;
    }

    .owl-nav {
        position: relative;
        height: 0px;
        position: absolute;
        width: 100%;
        top: 0px;

        .owl-prev {
            position: absolute;
            left: 0;
        }

        .owl-next {
            position: absolute;
            right: 0;
        }
    }
}


@media #{$small-only} {
    .promotions {
        .owl-nav {
            position: relative;
            height: 0px;
            position: absolute;
            width: 100%;
            top: 0px;

            .owl-prev {
                position: absolute;
                left: 35px;
            }

            .owl-next {
                position: absolute;
                left: 35px;
            }
        }
    }
}

.sponsors {
    padding: 1rem 4rem 3rem;

    .owl-nav {
        position: relative;
        height: 0px;
        position: absolute;
        width: 100%;
        top: 0px;

        .owl-prev {
            position: absolute;
            left: 0;
        }

        .owl-next {
            position: absolute;
            right: 0;
        }
    }

    .owl-prev span {
        position: absolute;
        left: -4rem;
        top: -7.5rem;
        font-size: 6rem !important;
        color: #a6a1a1;
    }

    .owl-next span {
        position: absolute;
        right: -4rem;
        top: -7.5rem;
        font-size: 6rem !important;
        color: #a6a1a1;
    }

    .owl-prev span:hover, .owl-next span:hover {
        color: $primary;
    }

    .item {
        padding: 1rem;
    }

    .owl-carousel .owl-item img {
        width: auto !important;
        margin: 0 auto;
    }
}

.listing {

    h2 {
        margin: 2rem 0 1rem;
        font-weight: 400;
        font-size: 1.2rem;

        a {
            color: #000;
            line-height: 1rem;
        }

        a:hover {
            color: $primary;
        }
    }


    .social a {
        display: inline-block;
        margin-left: .5rem;

        img {
            border-radius: 10px;
        }
    }


    a {
        display: inline-block;
    }

    .info {
        margin-top: 4rem;
    }

    .address {
        margin-top: 2rem;
    }

    .button {
        width: 100%;
    }

    a.button:hover {
        color: #fff;
        text-decoration: none;
    }

    .button:hover {
        background-color: $darkPrimary;
    }

    .ctas {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .fa {
        font-size: 2rem;
    }
}

.category-sub-head{
    text-align:left;
}


.ctas {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.masonry {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 2rem;
    -moz-column-gap: 2rem;
    column-gap: 2rem;
    padding: 0;

    .promotion {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
}

.exhibitors {
    h1 {
        margin-bottom: 1rem;
        font-size: 24px;
        line-height:28px;
    }
}


@media #{$small-only} {
    .masonry {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }
}
