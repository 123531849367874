.button:visited, .button:link {
    width: auto;
    height: 40px;
    line-height: 40px;
    text-transform: uppercase;
    color: $white;
    font-family: $lato;
    font-size: 16px;
    font-weight: 300;
    padding: 0 1rem;
    margin: 1rem auto 0;
    background: $primary;
    border-radius: 2px;

    &.new-button {
        display: inline-block;
        margin: 0;
        height: auto;
        line-height: 1;
    }

    &.tickets {
        background-color: $secondary;
        padding: 0 2.5rem;

        &:hover {
            background: scale-color($secondary, $lightness: -30%);
        }
    }

    &.ticket-deals {
        margin-top: 0.3rem;
        background-color: $dark-grey;
        padding: 1.4rem 2rem;
        line-height:0;

        &:hover {
            background: scale-color($dark-grey, $lightness: -30%);
        }
    }

    &.medium {
        font-size: 14px;
        padding: 10px 15px;
    }

    &.small {
        font-size: 12px;
        display: inline-block;
        margin: 0;
        height: auto;
        line-height: 1;
        padding: 10px;
    }

    &:hover, &:focus {
        background: scale-color($primary, $lightness: -30%);
        color: $white;
    }

    &.white {
        background: $primary;
        color: $white;

        &:hover, &:focus {
            background: scale-color($primary, $lightness: -10%);
        }
    }
}

button.disabled, button[disabled], .button.disabled, .button[disabled] {
    background: $grey;

    &:hover {
        background: $grey;
    }
}