﻿/*
*
* 9/25/2017 
*
* This file handles the badges/icons on the Exhibitor Listing Page for a particular show.
* 
* Pages -> Exhibitor List -> Exhibitors
* Edit the Listings Widget -> List Settings -> Edit List of listings

* Pages -> Exhibitor List -> Exhibitors
* Edit the Listings Widget -> Single Item Settings -> Edit Exhibitor Listing Detail Page
* 
*/

.badges-list .badge
{ 
	margin: 0 10px 0 0 !important;
	height: 28px;
}  