

.buyers-guide {
    position: relative;
    background-color: $primary;

    p {
        line-height: 1.7em;
        color: $white;
    }

    .button {
        background-color: #fff !important;
        color: $primary !important;
    }

    .button:hover {
        background-color: #fff !important;
        color: $primary !important;
    }


    .left {
        $min_height: 464px;

        background-image: url('https://mpe-ahfjd9gkc8gthjbc.z01.azurefd.net/sitefinity/images/default-source/flower-show/exhibitor-list.jpg');
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: $min_height;

        .no-sponsored-content-placeholder { //new css for the new hole on tempaltes 7/16/2018 {
            /*position: relative;

            img {
                position: absolute;
                width: 100%;
                z-index: 5; //Greater than the handles on the slick slider and the image .left above.
                max-height: 450px;
                //min-height: $min_height;
            }*/
        }

        .sponsored-content-slider {
            .slides {
                .slide {
                    min-height: $min_height + 1;
                }
            }
        }

        @media #{$small-only} {
            min-height: 0;
        }
    }

    .right {
        color: $white;
        position: relative;
        z-index: 2;
        padding: 6rem;

        h1 {
            color: $white;
        }
    }

    @media #{$small-up} {
    }

    @media #{$small-only} {
        .right {
            padding: 2rem;

            * {
                position: relative;
                z-index: 2;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
            }
        }
    }

    @media #{$large-up} {
        .right {
            //background-color: $primary;
        }
    }
}
