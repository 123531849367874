


.mobile-features-nudge {
    display: none;
    $offset: 15px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100000;
    box-shadow: 0 0 15px rgba(#000,0.5);

    *, & {
        box-sizing: border-box;
        -webkit-user-select: none;
        -moz-user-select: -moz-none;
        -ms-user-select: none;
        user-select: none;
    }

    a {
        color: #FFF;
    }

    header {
        position: relative;
        padding: 15px;
        padding-right: 45px;
        background-color: $medPrimary;
        color: #FFF;

        .title {
            h2, h3, h4, h5 {
                font-size: 0.95rem;
                margin: 0;
                color: #FFF;
                text-align: left;
                text-transform: uppercase;
            }
        }

        .subtitle {
            a, p {
                font-size: 0.8rem;
                margin: 4px 0 0;
                text-transform: uppercase;
                display: block;
            }
        }

        i {
            position: absolute;
            top: $offset;
            right: $offset;
        }
    }

    section.mobile-show-features {
        width: 100%;
        background-color: #FFF;
        overflow: hidden;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        height: 0;

        ul {
            $tile_size: 200px;
            float: left;
            padding: $offset;

            li {
                min-height: $tile_size;
                width: $tile_size;
                float: left;
                border: 1px solid #CCC;
                padding: $offset;

                h3 {
                    font-size: 0.9rem;
                    font-weight: normal;
                    margin: 0 0 5px;
                }

                figure {
                    margin: 0;
                    max-width: 100%;

                    img {
                        width: 100%;
                        display: block;
                    }
                }

                article {
                    p {
                        font-size: 0.8rem;
                        line-height: 1.5em;
                    }
                }

                + li {
                    margin-left: $offset;
                }

                .button {
                    margin-top: 1rem;
                }

                &.buy-tickets-box {
                    background-color: $primary;
                    background-image: url('/img/buy-tickets-bg-01.svg');
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: $tile_size;
                    border: none;
                    text-align: center;
                    padding: 25px 30px;
                    color: #FFF;
                    position: relative;
                    z-index: 1;

                    a {
                        &.cover-link {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            display: block;
                            z-index: 2;
                        }
                    }

                    h3 {
                        color: #FFF;
                        font-weight: bold;
                    }

                    article {
                        p {
                            i.fa-ticket {
                                font-size: 3rem;
                                color: $darkPrimary;
                            }

                            img {
                                display: block;
                                margin: 10px auto 15px;
                                height: 25px;
                            }
                        }
                    }

                    .button {
                        background-color: #FFF;
                        color: $primary;
                    }

                    &:after {
                        content: 'Scroll to see show features';
                        position: absolute;
                        top: 100%;
                        left: 0;
                        right: 0;
                        margin-top: 10px;
                        text-align: center;
                        color: darken(#FFF,30);
                        font-size: 0.8rem;
                    }
                }
            }
        }

        .slide-rail {
            width: 9999%;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    @media #{$small-only} {
        display: block;
    }
}
