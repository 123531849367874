/*
    Example usage: 
    @include animation(10s, 5s, changecolour)
 */

@mixin animation ($delay, $duration, $animation) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
	-webkit-animation-iteration-count: infinite;
    -webkit-animation-fill-mode: forwards;
	-webkit-animation-timing-function: linear;
 
    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
	-moz-animation-iteration-count: infinite;
    -moz-animation-fill-mode: forwards; 
	-moz-animation-timing-function: linear;
  
    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
	animation-iteration-count: infinite;
    animation-fill-mode: forwards;
	animation-timing-function: linear;
}