@import "normalize";
@import "foundation";
@import "mixins";
@import "variables";


@import "global";
 
@import "vendor/sitefinity";

@import "template/header";
@import "template/mobile-menu";
@import "template/buyers-guide";


@import "components/badges";
@import "components/buttons";
/*@import "components/hero";*/
@import "components/slides.scss";
@import "components/mobile-nudge";
@import "components/sponsored-content";
@import "components/ticket-sponsor";
@import "components/listings";

/* ----- Universal ----------------------- */

::selection {
    color: $white;
    background: $primary;
}

-moz-::selection {
    color: $white;
    background: $primary;
}

* {
    font-family: $lato;
}

html {
    overflow-y: scroll;
}

body {
    background: $white;
    color: $black;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    min-width: 250px;

    &.sfPageEditor {
        max-width: inherit;

        .inner-wrap {
            min-height: 450px;
            max-width: 1440px;
            margin: 0 auto;
        }
    }
}

iframe {
    max-width: 100%;
    border:0;
}

.inner-wrap {
    min-height: 245px;
}

a:link, a:visited {
    color: $primary;

    &:hover {
        color: scale-color($primary, $lightness: -30%);
    }
}

.required {
    /*color: $orange;*/
}

.sfError {
    color: red;
    margin-bottom: 20px;
}

.mt0 {
    margin-top: 0px !important;
}

.mt10 {
    margin-top: 10px !important;
}

.mt20 {
    margin-top: 20px !important;
}

.mb0 {
    margin-bottom: 0px !important;
}

.mb10 {
    margin-bottom: 10px !important;
}

.mb20 {
    margin-bottom: 20px !important;
}

.mb30 {
    margin-bottom: 30px !important;
}

.mb30 {
    h2 {
        margin-left: 8px;
    }
}

.tal {
    text-align: left !important;
}

.tar {
    text-align: right !important;
}

.tac {
    text-align: center !important;
}

.grey-bkgd {
    background: $lightGrey;
}

.secondary-bkgd {
    background: $secondary;
    position: absolute;
    top: 155px;
    height: 40px;
    width: 50%;
    z-index: 2;
}

.sfPageEditor .top-container .top-bar .grey-bkgd, .sfPageEditor .secondary-bkgd {
    display: none !important;
}

.sfPageEditor #ctl00_FlowerShowNavBar_ctl00_C {
    z-index: 1000;
}

.sfPageEditor .main-nav, .sfPageEditor #nav-ticket-link {
    top: 0px !important;
}

.sfPageEditor .mobile-nav-icon {
    top: 9px !important;
}

.interior.sfPageEditor .main-nav, .interior.sfPageEditor #nav-ticket-link {
    top: 90px !important;
}

.dark-grey-bkgd {
    background: $dark-grey;
}

.primary-bkgd {
    background: $primary;
    padding: 46px 0;
}

.white-bkgd {
    background: $white;
}

h2 {
    font-size: 24px;
    margin-bottom: 1rem;

    &.heading {
        font-family: $play;
        color: $darkGrey;
        font-size: 20px;
        padding-bottom: 5px;
        margin-bottom: 1rem;
        border-bottom: 2px solid $darkGrey;
        text-transform: uppercase;
    }
}

h1, h2, h3, h4 {
    font-family: $raleway;
    font-weight: 700;
    color: $dark-grey;
    text-transform: uppercase;
}

h2 {
    text-align: center;
}

p {
    margin: 10px 0;
    line-height: 1.2;
    color: #454b4f;
}


/* ----- Forms ------------------------ */
.example {
    margin: -.7rem 0 1rem 0;
    font-size: 80%;
    color: $grey;
}

.summary {
    p {
        margin: 0 0 1rem;
        font-size: 1rem;
    }

    label {
        color: $grey;
    }
}

label {
    color: $black;

    &.checkbox {
        color: $grey;
    }
}

input[type=checkbox], input[type=radio] {
    width: auto;
    margin: 0 5px 0 0;
    display: inline-block;
}

input[type="checkbox"] + label, input[type="radio"] + label {
    display: inline !important;
}

.sfFormCheckboxlist, .sfFormRadiolist {
    margin-bottom: 10px;
}


/* ----- Tables ----------------------- */
table {
    border-collapse: collapse;
    width: 100%;
    border: none;

    thead {
        background: none;

        tr {
            background: none !important;

            th {
                font-size: 12px;
            }
        }
    }

    tr.even, tr.alt, tr:nth-of-type(even) {
        background: none;
    }

    tr.odd, tr, tr:nth-of-type(odd) {
        background: scale-color($lightGrey, $lightness: 75%);
    }
}
/*----Employees------*/
.employee {
    text-align: center;
    padding: 0 15px;

    a.headshot {
        $photo_size: 145px;
        background-size: cover;
        background-position: top center;
        height: $photo_size;
        width: $photo_size;
        display: block;
        margin: 0 auto 10px;
    }

    h4 {
        font-size: 18px;
        line-height: 1.2em;

        small {
            display: block;
            margin: 5px 0 0;
            font-size: 0.8em;
            opacity: 0.6;
            line-height: 1;
            font-family: Lato,sans-serif;
        }

        a {
            color: $secondary;
        }
    }

    .bio-content {
        display: none;
    }

    + .employee {
        margin-top: 20px;
    }
}

.featherlight-content {
    .bio-content {
        width: 600px;

        figure {
            $photo_size: 155px;
            background-size: cover;
            background-position: top center;
            height: $photo_size;
            width: $photo_size;
            display: block;
            margin: 0 auto 10px;
            max-width: 100%;
        }

        h3 {
            line-height: 1.5em;
            color: $secondary;
            margin: 0 0 15px;

            small {
                display: block;
                font-family: Lato,sans-serif;
                text-transform: none;
                margin: 5px 0 0;
                opacity: 0.6;
            }
        }

        @media screen and (max-width: 720px) {
            width: auto;
        }
    }
}

.bio {
    min-height: 250px;
}

.sfPageEditor .bio {
    min-height: 300px;
}
/* ----- Header ----------------------- */
// Moved to template/_header.scss
/* ----- Mobile Nav ----------------------- */
// Moved to template/_mobile-menu.scss
.upcoming-events {
    max-width: 500px;
    z-index: 5;
    position: relative;

    .sfContentBlock {
        float: left;
        padding-top: 20px;
        width: 150px;

        p {
            font-size: 12px;
            text-align: right;
            line-height: 15px;
            text-transform: uppercase;
            margin: 0;
            color: $grey;
        }
    }

    .event {
        width: 118px;
        position: relative;
        padding: 5px;
        top: 25px;
        z-index: 2;
        float: right;

        img {
            height: 90px;
            width: 120px;
            -webkit-filter: grayscale(1);
            filter: grayscale(1);
        }

        &:hover {
            img {
                -webkit-filter: grayscale(0);
                filter: grayscale(0);
            }
        }

        /*&.orange {
            &:hover {
                background: $orange;

                &::after {
                    border-right-color: $darkOrange;
                }
            }
        }


        &.blue {
            &:hover {
                background: $blue;

                &::after {
                    border-right-color: $darkBlue;
                }
            }
        }

        &.green {
            &:hover {
                background: $green;

                &::after {
                    border-right-color: $darkGreen;
                }
            }
        }

        &.forestgreen {
            &:hover {
                background: $forestGreen;

                &::after {
                    border-right-color: $darkForestGreen;
                }
            }
        }

        &.marsala {
            &:hover {
                background: $marsala;

                &::after {
                    border-right-color: $darkMarsala;
                }
            }
        }

        &.teal {
            &:hover {
                background: $teal;

                &::after {
                    border-right-color: $darkTeal;
                }
            }
        }

        &.cdnteal {
            &:hover {
                background: $cdnTeal;

                &::after {
                    border-right-color: $cdnDarkTeal;
                }
            }
        }

        &.lightgreen {
            &:hover {
                background: $lightGreen;

                &::after {
                    border-right-color: $darkLightGreen;
                }
            }
        }

        &.lightblue {
            &:hover {
                background: $lightBlue;

                &::after {
                    border-right-color: $darkLightBlue;
                }
            }
        }

        &.terracotta {
            &:hover {
                background: $terracotta;

                &::after {
                    border-right-color: $darkTerracotta;
                }
            }
        }

        &.pink {
            &:hover {
                background: $pink;

                &::after {
                    border-right-color: $darkPink;
                }
            }
        }

        &.fallpink {
            &:hover {
                background: $fallPink;

                &::after {
                    border-right-color: $darkFallPink;
                }
            }
        }

        &.red {
            &:hover {
                background: $red;

                &::after {
                    border-right-color: $darkRed;
                }
            }
        }

        &.holidayblue {
            &:hover {
                background: $holidayBlue;

                &::after {
                    border-right-color: $darkHolidayBlue;
                }
            }
        }

        &.holidayred {
            &:hover {
                background: $holidayRed;

                &::after {
                    border-right-color: $darkHolidayRed;
                }
            }
        }

        &.christmasgreen {
            &:hover {
                background: $christmasGreen;

                &::after {
                    border-right-color: $darkChristmasGreen;
                }
            }
        }

        &.christmasred {
            &:hover {
                background: $christmasRed;
            }

            &::after {
                border-right-color: $darkChristmasRed;
            }
        }

        &.fallred2018 {
            &:hover {
                background: $fallRed2018;
            }

            &::after {
                border-right-color: $darkFallRed2018;
            }
        }

        &.outdoorpink2019 {
            &:hover {
                background: $outdoorPink2019;
            }

            &::after {
                border-right-color: $darkOutdoorPink2019;
            }
        }

        &.remodelingteal2019 {
            &:hover {
                background: $remodelingTeal2019;
            }

            &::after {
                border-right-color: $darkRemodelingTeal2019;
            }
        }

        &.springgreen2019 {
            background-color: $springGreen2019;

            &::after {
                border-right-color: $darkSpringGreen2019;
            }
        }

        &.mtlgreen {
            background-color: $mtlGreen;

            &::after {
                border-right-color: $darkMtlGreen;
            }
        }

        &.mtlorange {
            background-color: $mtlOrange;

            &::after {
                border-right-color: $darkMtlOrange;
            }
        }*/
    }
}

.left-small,
.right-small {
    height: 60px;
    width: 60px;
    border: none;

    .menu-icon {
        width: 60px;
        height: 60px;

        span::after {
            width: 24px;
            left: 20px;
            margin-top: -10px;
            box-shadow: 0 0 0 3px $primary, 0 10px 0 3px $primary, 0 20px 0 3px $primary;
        }
    }
}

hr {
    opacity: 1;
    border: 0px;
    height: .5rem;
    background: #f4f5f8;
    margin: 1rem 0;
}

.move-right .menu-icon span:after {
    box-shadow: 0 0 0 3px $darkPrimary, 0 10px 0 3px $darkPrimary, 0 20px 0 3px $darkPrimary;
}

.right-small {
    text-align: center;
    line-height: 70px;

    &.second {
        right: 50px;
        width: 50px;

        .fa {
            color: $primary;

            &:hover {
                color: $primaryHover;
            }
        }
    }

    .fa {
        font-size: 32px;
        color: $grey;

        &:hover {
            color: $primary;
        }
    }
}

.dashboard {
    .right-small {
        height: 45px;
        line-height: 45px;

        .fa {
            font-size: 28px;
            line-height: 45px;

            &:hover {
                color: $white;
            }
        }
    }
}

.has-tip {
    color: $primary;

    &:hover {
        color: $primaryHover;
    }
}

.f-dropdown {
    outline: none;
    text-align: right;
    background: rgba($white,.95);
    border-color: $lightGrey;
    border-radius: 1px;

    li {
        &:hover {
            background: $lightGrey;
        }

        a {
            color: $primary;
        }
    }

    &.open {
        left: 10px !important;
        right: 10px !important;
        width: auto !important;

        &:before {
            left: initial !important;
            right: 13px;
        }

        &:after {
            left: initial !important;
            right: 12px;
        }
    }
}


.left-off-canvas-menu {
    background: $primary;
}

/* ----- Footer ----------------------- */
footer {
    background: $lightGrey;
    text-align: left;
    color: $black;
    margin-top: 2rem !important;
    padding: 1rem 0;

    section {
        padding: 1rem 2rem;

        &.information-for-exhibitors {
            padding-bottom: 0;
        }
    }

    .bottom {
        p {
            text-transform: none;
        }

        h2 {
            text-transform: none;
            text-align: left;
        }
    }

    .social-links{
        margin-top:1rem;
    }
}

.sponsors, .information-for-exhibitors{
    font-size: 12px;
    text-transform: uppercase;
}

.bottom {
    font-size: 1rem;


    b {
        margin-top: .4rem;
        font-size: 18px;
        display:block;
        margin-bottom:1rem;
    }

    .social-links {
        margin-top: 1rem;
    }

    .inline-list {
        text-align: right;
        margin-top: 1rem;
        width: 100%;
        margin-bottom: 0px;
    }
}

.bottom ul, .bottom li {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: .5rem;
}


.sponsors {
    margin-top: -50px;

    .sfimagesList {
        margin: 0;

        li {
            display: inline-block;
            margin: 0 1.37rem 1.37rem 0;
            padding: 0;
            border: 2px solid #d0d0d0;
        }
    }
}

.information-for-exhibitors {

    .inline-list {
        list-style: none;
        margin-top: 1.5rem;

        li {
            border-right: 1px solid scale-color($grey, $lightness: 30%);
            padding: 0 13px 0 0;
            margin-bottom: 1rem;

            &.ro span {
                background-position: 0 -40px;
            }

            &.do span {
                background-position: 0 -80px;
            }

            &.mo span {
                background-position: 0 -121px;
            }

            &.et {
                border: none;

                span {
                    background-position: 0 -160px;
                }
            }

            &.single-line a {
                line-height: 40px;
            }

            span {
                /*background-image: $iconSprite;*/
                margin-right: 10px;
                background-size: 40px 200px;
                background-repeat: no-repeat;
                height: 40px;
                width: 40px;
                display: block;
                position: absolute;
                left: 0;
            }

            a {
                font-family: $raleway;
                font-weight: 700;
                text-transform: uppercase;
                height: 50px;
                line-height: 20px;
                font-size: 16px;
                vertical-align: middle;
                float: left;
                display: block;
                padding-left: 57px;
                position: relative;
            }
        }
    }
}

.bottom {


    .social-links {
        margin-left: 0;

        li {
            margin: 0 5px 0 0;


            a {
                background-color: ($primary);
                /*background-image: $socialSprite;*/
                background-size: 27px 192px;
                background-repeat: no-repeat;
                height: 27px;
                width: 27px;
                display: block;
                float: left;
                color: #fff;
                text-align: center;
                padding: 2px 0;
            }
        }
    }

    .subscribe-form {
        width: 350px;
        margin-top: 25px;

        input[type=email] {
            display: inline-block;
            float: left;
            height: 40px;
            width: 250px;
            border: 2px solid $primary;
            margin: 0;
        }

        input[type=submit] {
            display: inline-block;
            float: left;
            height: 40px;
            width: 100px;
            background: $primary;
            margin: 0;
            text-transform: uppercase;
            text-align: center;
            color: $white;
            padding: 0;
        }
    }

    .right {
        font-size: 16px;
        text-transform: none;

        .inline-list {
            margin: 0 0 .5rem;

            li {
                float: right;
                border-left: 1px solid $black;
                padding-left: 10px;
                margin-left: 10px;

                &:last-child {
                    border: none;
                }

                a {
                }

                a.phone {
                    color: $black;
                }

                &.privacy {
                    border-color: $black;

                    a {
                        color: $black;
                    }
                }
            }

            &.second {
                text-transform: uppercase;
            }
        }
    }
}
/* ----- Interior slide ------*/
#Slideshow {
    &.subpage {
        #Slides {
            max-height: 200px;
            background-size: cover;
            margin-bottom:2rem;
        }
    }
}
/* ----- Main Content ----------------------- */
.main-content {
    padding-top: 30px;
}

.primary-content, .sidebar-content {
    border-right: 1px solid $grey;
    padding-left: 2rem;
    padding-right: 3.5rem;
    margin-bottom: 2rem;

    h1 {
        margin-top:1rem;
        color: $secondary;
    }

    h2 {
        text-align: left;
        font-size: 1.25rem;
    }

    p {
        line-height: 1.75rem;
    }

    ul.pagination {
        li {
            display: inline-block;

            a {
                margin: 10px 15px 10px 0;
                padding: 10px;

                span.sr-only {
                    display: none;
                }
            }
        }
    }

    .sfContentBlock, .sfimagesTmbList {
        @extend .clearfix;
        border-bottom: 1px solid $grey;
        padding: 0 0 2rem;
        margin-bottom: 2rem;
        line-height: 1.75rem;

        &:last-child {
            border-bottom: none;
        }

        h1:first-of-type,
        h2:first-of-type,
        h3:first-of-type,
        h4:first-of-type {
            margin-top: 0;
        }

        p:last-of-type {
            margin-bottom: 0;
        }

        h2 {
            margin: 2rem 0 1rem;
        }

        h3 {
            border-bottom: none;
        }

        hr {
            border: none;
            height: 1px;
            background: $grey;
            @extend .clearfix;
        }

        table {
            td {
                border: none;
            }

            @include table;
        }
    }
}

.top-alert {
    padding: 7px 0;

    .alert-area {
        img#alert-sign {
            float: left;
            padding-right: 10px;
            padding-top: 8px;
        }

        p#alert-text {
            font-size: 14px;
            font-family: $raleway;
            font-weight: bold;
            color: $white;

            a {
                color: white;
                text-decoration: underline;
                padding-left: 5px;

                @media #{$small-only} {
                    text-decoration: none;
                }
            }
        }
    }

    .top-social-area {
        float:right !important;

        ul#social-media-area {
            float: right;
            list-style: none;
            text-transform: uppercase;
            margin: 0;
            padding: 0;

            li {
                line-height: 40px;
                display: inline-block;
                position: relative;

                &.icon {
                    padding-right: 3px;

                    img {
                        @media #{$small-only} {
                            width: 22px;
                        }
                    }

                    &:last-child {
                        padding-right: 10px;

                        @media #{$large-up} {
                            padding-right: 0;
                        }
                    }
                }

                &.first {
                    padding-left: 5px;

                    @media #{$small-only} {
                        padding-left: 0;
                    }

                    @media #{$large} {
                        padding-left: 15px;
                    }
                }

                &.join {
                    padding-right: 5px;

                    @media #{$small-only} {
                        padding-right: 6px;
                    }

                    img {
                        @media #{$small-only} {
                            width: 22px;
                        }
                    }

                    &:nth-child(2) {
                        padding-right: 15px;
                    }
                }

                a {
                    color: $white;
                    font-weight: bold;
                }
            }
        }
    }
}

.introduction {
    padding-top: 95px;

    p#introduction {
        font-size: 1.5rem;
        line-height: 1.6;
        text-align: center;
    }

    .cta {
        padding: 10px 0;

        .button {
            display: block;
            margin: 5px auto;
        }
    }

    .event-information {
        padding: 95px 0;
        text-align: center;

        img {
            display: block;
            margin-left: auto;
            margin-right: auto
        }

        p.intro {
            padding-top: 20px;
            font-family: $raleway;
            color: $primary;
            font-weight: 700;
            font-size: 1.5rem;
            text-transform: uppercase;
        }

        p.address {
            color: $primary;
        }
    }
}

.gardens-features-area {
    padding-top: 15px;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: -30px;

    figure.show-features {
        margin: 0;
        position: relative;
        

        img {
            width: 100%;
            display: block;
        }

        figcaption {
            position: absolute;

            h3, p, a, p a {
                color: $white;
                text-align: center;
                display: block;
            }
        }
    }

    figure {
        margin: 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            display: block;
        }

        figcaption {
            position: absolute;

            h3, p, a, p a {
                color: $white;
                text-align: center;
            }
        }
    }
}
/*.col5-unit{
    @include grid-column(2.4);
    padding-left:0;
    padding-right:10px;
    &:nth-child(5){
        padding-right:0;
    }
}*/ .advertisement {
    margin-bottom: -15px;
}

section.three-col-ads {
    padding: 90px 0;

    @media #{$small-only} {
        padding: 65px 0;
    }

    img {
        padding: 0 15px;

        @media #{$small-only} {
            padding: 0 20px;
        }
    }
}

section.twitter-area {
    padding: 22px 0;

    p {
        color: $white;
        line-height: 1.6;
        font-size: 1.1rem;
    }

    a.button {
        float: right;
    }

    .latest-update {
        img {
            padding-top: 16px;
            padding-right: 10px;
            float: left;
        }

        h4 {
            color: $primary;
            padding-top: 4px;
            margin-bottom: -12px;
        }
    }
}

.subscribe-social-media-area {
    h3 {
        color: $white;
    }

    #social-links {
        padding-top: 8px;

        img {
            padding-right: 35px;
        }
    }
}

.primary-content {
    img {
        margin: 0 10px 10px;
    }

    .sf_pagerNumeric {

        a {
            margin: 10px 15px 10px 0;
            padding: 10px;
            color: $black;
        }

        a.sf_PagerCurrent {
            margin: 10px 15px 10px 0;
            padding: 10px;
            color: $primary;
        }
    }
}

.sidebar-content {
    border-right: none;
    padding-right: 2rem;
    padding-left: 3.5rem;

    p {
        line-height: 1rem;
        font-size: .85rem;
    }

    .sidebar-advertisements {

        div {
            display: block;
            position: relative;
            height: 250px;
            width: 250px;
            margin-bottom: 2rem !important;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 300px;
                height: 250px;
                max-width: 300px;
            }
        }
    }
}

.galleria-container {
    background: $lightGrey;

    img {
        margin: 0;
    }
}

.galleria-thumbnails .galleria-image {
    background: $lightGrey;
    border: none;
}

.mfp-figure {
    figcaption {
        position: initial !important;
    }
}

/* ----- Homepage ----------------------- */

#Slideshow #Slides .slide .sponsor {
    bottom: 1.5rem !important;
}

#Slideshow #Slides .slide .sponsor {
    bottom: 1.5rem !important;
}

#Slideshow #Slides .slide .sponsor .intro {
    color: #fff;
    font-weight: bold;
    margin-bottom: .5rem;
    min-height: 25px;
    background-color: rgba(0,0,0,.3);
    display: inline-block;
    padding: 0 8px;
}

#Slideshow #Slides .slide .sponsor img {
    max-width: revert;
    display:block;
    margin:0 auto;
}

@media (max-width: 640px) {

    .slide-img{
        position:relative !important;
    }


    #Slideshow #Slides .slide .sponsor {
        bottom: 1rem !important;
    }

    #Slideshow #Slides .slide .sponsor img {
        max-width: 200px !important;
        max-height:50px;
    }
}


.show-features {
    margin: 1rem auto;
    padding: 90px 0;

    .columns {
        padding-left: .5rem;
        padding-right: 0.5rem;
    }

    .feature {
        /*border: 1px solid scale-color($lightGrey, $lightness: -10%);*/
        /*padding: 1.5rem;*/
        margin: 0;
    }

    h4.heading {
        color: $primary;
        text-transform: uppercase;
        font-size: 14px;
        font-family: $lato;
        border-top: 2px solid $darkGrey;
        padding-top: 3px;
        margin-bottom: 0;
    }

    h2 {
        margin: 1rem 0;
        line-height: 20px;

        a {
            color: $black;
            font-size: 15px;
            font-family: $play;
            text-transform: none;
        }
    }

    p {
        color: $darkGrey;
        min-height: 7.5rem;
    }

    .button {
        width: 130px;
        margin: 1.5rem auto 0;
        display: block;
    }
}

.k-animation-container .k-list-container {
    background-color: $white;

    .k-item {
        border: 0 solid #000;
    }

    .k-state-hover {
        background-color: $primary;
        color: $white;
    }
}

.sfsearchBox {
    width: 100%;
    border: 0;
    padding: 0;
    margin-top: 0 !important;
}

.sfsearchBox .sfsearchTxt {
    width: 75%;
    margin-right: 10px;
}

.sfsearchBox .sfsearchSubmit {
    background: $primary;
    color: $white;
    width: auto;
    height: 30px;
    line-height: 30px;
    text-transform: uppercase;
    color: $white;
    font-family: "Lato",sans-serif;
    font-size: 16px;
    font-weight: 300;
    padding: 0px 1rem;
    margin: 1rem auto 0px;
    border: 0px;
    display: block;
    margin-left: 0px;
}


.three-col-ads {
    margin: 1rem auto;
}

.two-col-ads {
    margin: 1.5rem auto;
}

.sfpostAuthorAndDate {
    margin-bottom: 1rem;
    color: $grey;
}

.from-the-blog {
    padding: 100px 0;

    .blog-feature {
        min-height: 430px;
        background-color: $white;
        border:1px solid #ddd;
    }

    img {
        margin: 0 1rem 1rem 0;
    }

    h2 {
        text-align:left;
        padding: 5px 25px;
        line-height: 20px;

        a {
            color: $black;
            font-size: 18px;
            font-family: $raleway;
            text-transform: none;
        }
    }

    p {
        color: $grey;
        padding: 0 25px;
        clear: both;
        min-height: 120px;
        line-height: 1.6;
    }

    .button {
        margin-left: 25px;
        margin-bottom: 25px;
        //display: flex;
        //align-items: center;
        //justify-content: center;
        //width: 275px;
        //margin-top: 40px;
    }
}

.grey-bkgd .from-the-blog .blog-feature {
    border:0;
}


.twitter-and-exhibitor {
    margin: 2rem auto;

    .twitter-feed {
        padding-right: 1rem;
    }

    .ads {
        .columns {
            padding-left: 0;
        }
    }
}
/* ----- Modal ----------------------- */
.modal {
    padding: 0;

    .primary, .upcoming, .bottom {
        padding: 2rem;
    }

    .primary {
        background: $primary;
        color: $white;
        padding: 2rem 4rem;

        h1 {
            color: $white;
            font-weight: bold;
        }
    }

    .upcoming {
        .large-4 {
            border-right: 1px solid $grey;
            padding: 0 2rem;

            &:last-child {
                border: none;
            }

            img {
                width: 150px;
                padding-bottom: 20px;
                border-bottom: 2px solid $darkGrey;
                margin: 0 0 10px;
            }

            h4 {
                margin: 0;
            }

            p {
                margin: 0;
            }
        }
    }

    .bottom {
        background: $lightGrey;
        padding: 3rem 1rem;

        h2 {
            margin-top: 0;
        }

        .large-6 {
            padding: 0 3rem;
            margin-bottom: 0;

            &:first-child {
                border-right: 1px solid $grey;

                p {
                    text-transform: none;
                    line-height: 1.5;
                }
            }
        }

        ul {
            list-style: none;
            margin-left: 0;

            li {
                position: relative;
                line-height: 1rem;
                margin-bottom: .5rem;
                color: $grey;

                &:before {
                    content: "";
                    display: block;
                    width: 0;
                    height: 0;
                    border: inset 6px;
                    border-color: transparent transparent transparent $primary;
                    border-left-style: solid;
                    top: 3px;
                    margin-left: 0;
                    position: absolute;
                    left: -20px;
                }
            }
        }
    }

    &.reveal-modal .close-reveal-modal {
        color: $white;
    }
}

.sfPageEditor .modal {
    display: block;
    visibility: visible;

    &.hidden {
        display: none;
        visibility: hidden;
    }
}
/* ----- Image Rotator ----------------------- */
.full-width-banner, .full-width {
    width: 100%;
}

#HeroContainer .sfimageWrp {
    content: "";
    background: transparent linear-gradient(to right, rgb(34, 34, 34) 0%, rgba(34, 34, 34, 0) 100%) repeat scroll 0% 0%;
    display: block;
    height: 100%;
    text-align: center;
    overflow: hidden;

    @media #{$small-only} {
        background: none;
    }
}

#HeroContainer .sfimageWrp img {
    position: relative;
    z-index: -1;
    width: 100%;
}

#HeroContainer div {
    width: 100%;
}
/* ----- Exhibitor Listings ---------------------*/
.listing {
    .city-postal-zip {
        margin: .5em 0;
    }
}

.listing h2 {
    margin-bottom: 0px
}
/* ----- Lists ----------------------- */
.numbered-list {
    list-style: none;
    margin: 0;
}

.number-heading {
    font-family: $play;
    color: $grey;
    font-weight: normal;
    letter-spacing: 0.05em;
    margin-bottom: 10px;
}

.number {
    display: inline-block;
    text-align: center;
    background: $primary;
    border: 2px solid $lightGrey;
    color: $white;
    margin: -3px 5px 0 0;
    height: 30px;
    width: 30px;
    line-height: 28px;
    font-family: $play;
    border-radius: 25px;
    font-size: 16px;
    vertical-align: middle;
}

@media #{$small-up} {
    .full-width-banner {
        margin-top: 0;
    }

    .from-the-blog {
        padding: 4.5rem 0;

        .columns {
            margin-bottom: 2rem;
        }
    }

    .twitter-and-exhibitor .image-column {
        padding: 0 0.9375rem;
    }

    .modal {
        .bottom {
            .large-6 {
                &:first-child {
                    border: none;
                    margin-bottom: 3rem;
                }
            }
        }
    }
}

@media #{$small-only} {
    h1, h2, h3, h4 {
        clear: both;
    }

    .top-alert {
        .alert-area {
            img#alert-sign {
                margin-left: 10px;
            }

            p#alert-text {
                .alert-text-area {
                    white-space: nowrap;
                    width: 120px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .top-container {
        .top-bar {
            height: 156px;

            .top-bar-section {
                .logo {
                    width: 140px;

                    img {
                        height: 105px;
                    }
                }

                .event-info {
                    width: fit-content;
                    left: 135px;
                    top: 40px;
                    line-height: 20px;

                    p {
                        font-size: 12px;
                        color: $dark-grey;

                        &:first-child {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        &.is-fixed {
            .top-bar {
                .top-bar-section {
                    .logo {
                        width: 140px;
                        top: 0px;

                        img {
                            left: 135px;
                            top: 40px;
                            height:105px;
                        }
                    }

                    .event-info {
                        left: 135px;
                        top: 40px;
                    }
                }
            }
        }
    }

    .primary-content, .sidebar-content {
        border-right: none;
        padding: 0px;

        img {
            margin: 10px 0;
        }
    }

    .bottom .right {
        margin-top: 1rem;
    }

    .from-the-blog {
        padding: 3rem 0 1rem;

        h2 {
            font-size: 2.125rem;
        }

        .blog-feature {
            margin-left: 10px;

            img {
                width: 100%;
            }
        }

        .button {
            margin-top: 0;
            margin-bottom: 40px;
        }
    }

    .twitter-feed {
        margin-bottom: 2rem;
    }

    .information-for-exhibitors .inline-list li {
        width: 100%;
        float: left;
        display: block;
        border: none;
    }

    .information-for-exhibitors {
        h2 {
            font-size: 2.125rem;
            text-align: left;
        }
    }

    .bottom {
        .left, .right {
            width: 100%;
            margin-bottom: 1rem;
        }

        .subscribe-form {
            width: 100%;
        }

        .subscribe-form input[type=email],
        .subscribe-form input[type=submit] {
            width: 100%;
        }
    }

    .ads {
        img {
            display: block;
            margin: 0 auto 1.5rem;
        }
    }

    .blog-feature {
        background-color: $white;

        p {
            min-height: 0;
        }
    }

    .show-features {
        .columns {
            padding: 0 1rem;
        }

        .feature {
            margin-bottom: 1rem;
        }

        .button {
            margin-left: 0;
        }

        p {
            min-height: 0;
        }
    }

    .gardens-features-area {
        padding-left: 15px;
        padding-right: 15px;

        .col5-unit.columns {
            padding-left: 0;
            padding-right: 0;
        }

        figure.show-features {
            margin-bottom: 15px;

            img {
                height: 220px; /* FIX THIS TO BE 180px*/
            }

            figcaption {
                background-color: transparent;
                bottom: 15%;
                left: 20%;
                width: 60%;
            }

            h3 {
                font-size: 2.75rem;
                line-height: 50px;
            }

            p {
                display: none;
            }
        }

        figure {
            margin-bottom: 15px;
            display: block;

            figcaption {
                position: relative;
                background-color: #f5f5f5;
                width: 100%;
                left: 0;

                h3, h3 a {
                    color: $dark-grey;
                    text-align: center;
                }
            }
        }
    }

    .introduction {
        padding: 2rem;

        .event-information {
            padding: 40px 0 0 0;

            .event-detail {
                margin-bottom: 50px;
            }
        }
    }

    .buyers-guide {
        .left {
            min-height: 280px;
        }

        .right {
            &:after {
                /*background-color: $dark-grey;*/
            }
        }
    }

    section.subscribe-social-media-area {
        padding: 46px 20px;

        h3 {
            font-size: 2rem;

            &:first-of-type {
                margin-left: 20px;
            }
        }

        #social-links {
            padding-left: 20px;
        }

        a.button {
            margin-left: 20px;
            margin-bottom: 50px;
        }
    }

    section.twitter-area {
        padding: 20px 20px 40px;

        .latest-update {
            margin-left: 20px;
        }

        a.button {
            float: left;
        }
    }

    .sponsors {
        .row {
            padding: 0 10px;
        }
    }

    .modal {
        .primary {
            padding: 2rem;
        }

        .upcoming .event {
            border: none;
            margin-bottom: 2.5rem;
        }

        .bottom {
            .columns {
                border: none;
                padding: 0 1rem;
            }
        }
    }
}

@media #{$medium-only} {
    .top-alert {
        .alert-area {
            img#alert-sign {
                margin-left: 10px;
            }
        }
    }

    .top-container {
        .top-bar {
            height: 156px;

            .top-bar-section {
                .logo {
                    width: 140px;
                    top: -15px;

                    img {
                        height: 80px;
                    }
                }

                .event-info {
                    left: 160px;
                    top: 20px;
                }
            }
        }

        &.is-fixed {
            .top-bar {
                .top-bar-section {
                    .logo {
                        width: 140px;
                        top: -15px;

                        img {
                            margin-top: 30px;
                            height: 80px;
                        }
                    }

                    .event-info {
                        left: 160px;
                        top: 20px;
                    }
                }
            }
        }
    }

    .blog-feature {
        p {
            min-height: 0;
        }
    }

    .introduction {
        .event-information {
            padding: 30px 0 0 0;

            .event-detail {
                margin-bottom: 50px;
            }
        }
    }

    .gardens-features-area {
        .column, .columns {
            padding-left: 0.3rem;
            padding-right: 0.3rem;
            padding-bottom: 0.6rem;
        }

        figure {
            figcaption {
                position: absolute;

                h3, p, a, p a {
                    color: $white;
                    text-align: center;
                    display: none;
                }
            }

            &:hover {
                img {
                    -webkit-filter: grayscale; /*sepia, hue-rotate, invert....*/
                    -webkit-filter: brightness(50%);
                }

                h3, p, a, p a {
                    color: $white;
                    text-align: center;
                    display: block;
                }

                h3 {
                    font-size: 0.85em;
                }
            }
        }

        figure.show-features {
            margin-bottom: 15px;

            img {
                height: 180px;
            }

            figcaption {
                background-color: transparent;
                bottom: 30%;
                left: 18%;
                width: 60%;
            }

            h3 {
                font-size: 2.75rem;
                line-height: 50px;
            }

            p {
                display: none;
            }
        }
    }

    .from-the-blog {
        .button {
            margin-top: 0;
            margin-bottom: 40px;
        }
    }

    .show-features {
        .columns {
            padding: 0 1rem;
        }

        .feature {
            margin-bottom: 1rem;
        }

        .button {
            margin-left: 0;
        }

        p {
            min-height: 0;
        }
    }

    section.subscribe-social-media-area {
        padding: 45px 20px;
    }

    .primary-content {
        border-right: none;
    }

    .sidebar-content {
        img {
            display: block;
        }
    }
}

@media #{$large-up} {
    .col5-unit {
        @include grid-column(2.4);
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        margin-bottom: 15px;
    }

    figure.show-features {
        display: flex;
        justify-content: center;
        align-items: center;

        figcaption {
            position: absolute;
            top: 30%;
            width: 45%;
        }
    }

    figure#redbox-inline {
        display: flex !important;
    }

    figure {
        margin: 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            display: block;
        }

        figcaption {
            position: absolute;

            h3, p, a, p a {
                color: $white;
                text-align: center;
                display: none;
            }
        }

        &:hover {
            img {
                -webkit-filter: grayscale; /*sepia, hue-rotate, invert....*/
                -webkit-filter: brightness(50%);
            }

            h3, p, a, p a {
                color: $white;
                text-align: center;
                display: block;
            }
        }
    }
}

@media #{$large-up} {
    .ads {
        img {
            display: inline-block;
            margin: 0;
        }
    }

    .from-the-blog {
        padding: 4.5rem 0;

        .columns {
            margin-bottom: 0;
        }
    }

    .twitter-container {
        height: 452px;
        overflow-y: scroll;
    }

    .twitter-and-exhibitor .image-column {
        padding-left: 0;
    }
}

.field-validation-error {
    color: $errorRed;
}

#back-to-top {
    position: fixed;
    /*right: 100px;*/
    bottom: 25px;
    z-index: 9999;
    width: 46px;
    height: 46px;
    background-color: $primary;
    opacity: 1;
    display: none;
    border-radius: 50%;
    text-align: center;
    font-size: 21px;
    color: #fff;
    cursor: pointer;
    line-height: 46px
}

@media #{$small-only} {
    #back-to-top {
        bottom:75px;
    }
}

.grecaptcha-badge {
    z-index: 1000 !important
}

.accessibility-alt {
    position: absolute;
    left: -100000px
}
/* ----- Testimonials ----------------------- */
.jcarousel-wrapper {
    margin: 0 auto;
    position: relative;

    .sf_pagerNumeric {
        display: none;
    }
}

.jcarousel-control-prev {
    position: absolute;
    right: 90%;
    top: 25%;
    z-index: 10;
}

.jcarousel-control-next {
    position: absolute;
    left: 90%;
    top: 25%;
    z-index: 10;
}

.jcarousel {
    position: relative;
    overflow: hidden;
}

.jcarousel ul {
    width: 20000em;
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
}

.jcarousel li {
    float: left;
}

.testimonials {
    text-align: center;
}

.testimonial {
    margin: 0 36px;
    text-align: center;
}

.alert-bar {
    width: 100%;
    background-color: $primary;
    text-align: center;
    padding: 10px 20px;
    line-height: 1.2rem;

    a {
        width: 100%;
        display: block;
        line-height: 2rem;
        font-size: 18px;
    }
}

.mpe-logo {
    height: 30px;
}


.button {
    display: inline-block;
    background-color: $primary;
    color: #fff;
    text-transform: uppercase;
    padding: .5rem 2rem;
    border: 0px;
    text-align: center;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

@media #{$small-only} {
    .sponsors .row {
        display: flex;
        flex-wrap: wrap;

        div.sponsor {
            width: 27%;
            margin: 3%;
            text-align: center;
        }
    }

    footer section{
        padding:1rem 0;
    }

    .sponsors{
        padding:1rem 0;
    }
}


@media #{$medium-up} {
    .sponsors .row {
        display: flex;
        flex-wrap: wrap;

        h2{width:100%;}

        div.sponsor {
            width: 10%;
            margin:2%;
        }
    }
}

.show-feature {
    width: 100%;
    display: block;
    text-decoration: none;
    margin-bottom: 1rem;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #f5f5f5;

    img {
        width: 100%;
        margin: 0;
    }

    h2 {
        margin-top: 0;
    }

    .sponsored-by-label {
        text-transform: uppercase;
        text-align: left;
    }

    .sponsor-logos {
        text-align: left;

        img {
            margin: 1rem;
            width: auto;
        }
    }

    .copy {
        margin: 1rem;
        text-align:left;
    }

    .button {
        margin-bottom: 0;
    }

    .learn-more {
        text-align: center;
        width: 100%;
        display: block;

        .learn-more-button {
            display: inline-block;
            width: auto;
            height: 40px;
            line-height: 40px;
            text-transform: uppercase;
            color: #fff;
            font-family: "Lato",sans-serif;
            font-size: 16px;
            font-weight: 300;
            padding: 0 1rem;
            margin: 1rem auto 0;
            background: $primary;
        }
    }

    @media screen and (max-width: 720px) {
        .learn-more {
            text-align: center;
        }
    }
}

.show-feature-content {
    display: none;
}

.add-padding{
    padding:90px 0;
}

button, .button {
    background-color: $primary;
}

.sf-search-result-container {
    p{
        display:none;
    }
}

.pagination {
    margin-left: 0;
    margin-top: 20px;

    .page-link {
        background-color: $primary;
        color: #fff;
        padding: 10px 15px !important;
        margin: 10px 10px 0 0 !important;
    }

    .page-link:hover {
        font-weight: 700;
        color: #fff;
        background-color: scale-color($primary, $lightness: -30%)
    }

    .active .page-link {
        font-weight: 700;
        background-color: scale-color($primary, $lightness: -30%)
    }
}


.button:hover {
    background-color: scale-color($primary, $lightness: -30%);
}


.faq {
    margin-bottom:1rem; 

    .question {
        display: block;
        background-color: #eee;
        padding: .5rem 1rem;
        border: 1px solid #ccc;
        border-left: 0;
        border-right: 0;
        color: #000;

        svg {
            float: right;
            width: 20px;
            height: 20px;
            margin-top: 2px;
        }
    }

    .question:hover {
        cursor: pointer;
    }

    .answer {
        display: none;
        padding: 1rem;
    }
}

.primary{
    color:$primary;
}

.blog-list {
    img{
        width:100%;
    }
}

.promotion img{
    margin:0;
}

.fotorama .fotorama__nav-wrap {
    margin-top:1rem;
}

.fotorama__thumb{
    background-color:#fff !important;
    margin:0 -8px;
}

.d-none{display:none;}


.grey-bkgd hr {
    background:#ddd;
}