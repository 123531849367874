ul {
    &.plain {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

a {
    &, &:visited, &:active, &:focus {
        outline: none;
    }
}

.group {
    zoom: 1; /* For IE 6/7 (trigger hasLayout) */

    &:before, &:after {
        content: "";
        display: table;
    }

    &::after {
        clear: both;
    }
}