section.sponsored-content-slider {
    background-color: darken(#FFF,5);
    position: relative;

    a {
        &.arrow {
            $arrow_offset: 15px;
            position: absolute;
            color: darken(#FFF,30);
            top: 280px;
            z-index: 3;

            &.prev {
                left: $arrow_offset;
            }

            &.next {
                right: $arrow_offset;
            }
        }
    }

    section.slide {
        display: none;
        position: relative;
        z-index: 2;

        figure {
            margin: 0;
            height: 200px;
            width: 100%;
            background-size: cover;
        }

        article {
            min-height: 200px;
            padding: 25px 50px 30px;
            //padding-right: 50px;

            hgroup {
                margin: 0 0 10px;

                h4 {
                    margin: 0;
                    font-size: 0.9rem;
                    color: rgba($medPrimary,0.3);
                }

                h3 {
                    margin: 5px 0 0;
                    font-size: 1.6rem;
                    line-height: 1.2em;

                    a {
                        //color: #FFF;
                    }
                }
            }

            p {
                margin: 10px 0 15px;
            }

            a.button {
                //background-color: #FFF;
                //color: $primary;
            }

            @media #{$small-only} {
                padding: 2em;
            }
        }

        &:first-child, &.slick-slide {
            display: block;
        }
    }

    ul.slick-dots {
        margin: 0;
        padding: 0;
        list-style: none;
        line-height: 1;
        //text-align: center;
        position: absolute;

        li {
            display: inline-block;

            button {
                $dot_size: 12px;
                font-size: 0;
                line-height: 0;
                padding: 0;
                height: $dot_size;
                width: $dot_size;
                background-color: $medPrimary;
                border-radius: 100%;
                opacity: 0.6;
                margin: 0;
                display: block;
            }

            + li {
                margin-left: 5px;
            }

            &.slick-active {
                button {
                    background-color: #FFF;
                }
            }
        }
    }

    @media #{$medium-only} {
        min-height: 380px;
    }
}