#Slideshow {
    width: 100%;
    position: relative;
    z-index: 1;

    &, * {
        box-sizing: border-box;
    }

    #Slides {
        width: 100%;
        overflow: hidden;
        top: 0;
        left: 0;
        position: relative;
        z-index: 4;

        .slide {
            width: 100%;
            background-size: cover;
            background-position: center center;
            margin: 0;
            position: relative !important;
            min-height: 600px;
            display: none;

            figure.slide-img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-size: cover;
                margin: 0;
                background-position: center bottom;
                z-index: 1;
            }

            .sponsor {
                position: absolute;
                bottom: 0;
                width: 100%;
                text-align: center;
                z-index: 500;

                img {
                    display: inline-block;
                    max-width: 200px;
                    max-height: 100px;
                }
            }

            div.caption-text {
                position: relative;
                z-index: 3;
                margin: 190px 0 0;
                padding-left: 0;

                h1 {
                    margin: 0;
                    font-size: 46px;
                    line-height: 50px;
                    padding: 0;
                    color: $white;
                    font-weight: bold;
                }

                p {
                    margin: 10px 0 0;
                    padding: 0;
                    color: $white;

                    + a.button {
                        margin: 1rem 0 0;
                    }
                }
            }

            &::before {
                content: '';
                background: -moz-linear-gradient(left, rgba(34,34,34,1) 0%, rgba(34,34,34,0) 100%); /* FF3.6+ */
                background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(34,34,34,1)), color-stop(100%,rgba(34,34,34,0))); /* Chrome,Safari4+ */
                background: -webkit-linear-gradient(left, rgba(34,34,34,1) 0%,rgba(34,34,34,0) 100%); /* Chrome10+,Safari5.1+ */
                background: -o-linear-gradient(left, rgba(34,34,34,1) 0%,rgba(34,34,34,0) 100%); /* Opera 11.10+ */
                background: -ms-linear-gradient(left, rgba(34,34,34,1) 0%,rgba(34,34,34,0) 100%); /* IE10+ */
                background: linear-gradient(to right, rgba(34,34,34,1) 0%,rgba(34,34,34,0) 100%); /* W3C */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#222222', endColorstr='#00222222',GradientType=1 ); /* IE6-9 */
                display: block;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                width: 60%;
                z-index: 2;
            }

            &:first-child, &.slick-slide {
                display: block;
            }
        }

        .mvc {
            @media #{$small-only} {
                display: block;
            }
        }
    }

    ul.slick-dots {
        width: 100%;
        margin: 0;
        position: absolute;
        bottom: 0;
        z-index: 9;
        text-align: center;
        padding: 0;

        li {
            display: inline-block;

            button {
                height: 15px;
                width: 15px;
                border-radius: 15px;
                background: $white;
                display: inline-block;
                margin-right: 5px;
                font-size: 0;
                line-height: 0;
                padding: 0;

                &:hover, &:focus {
                    background: scale-color($white, $lightness: -20%);
                    outline: none;
                }
            }

            &.slick-active {
                button {
                    background-color: $primary;
                }
            }
        }
    }

    .slideshowArrow {
        $arrow_offset: 30px;
        top: 50%;

        &:link, &:visited, &:focus {
            position: absolute;
            z-index: 9;
            cursor: pointer;
            font-size: 30px;
            color: rgba($primary, .8);
            outline: none;

            &:hover, &:focus {
                color: scale-color($primary, $lightness: -20%);
            }
        }

        &#Left {
            left: $arrow_offset;
        }

        &#Right {
            right: $arrow_offset;
        }
    }

    @media #{$large-up} {
        #Slides {
            .slide {
                .row {
                    .tl {
                        position: relative;
                        top: -40px;
                    }

                    .t {
                        position: relative;
                        top: -75px;
                        width: 100%;
                        text-align: center;
                    }

                    .tr {
                        position: relative;
                        top: -75px;
                        width: 100%;
                        text-align: right;
                    }

                    .l {
                    }

                    .c {
                        width: 100%;
                        text-align: center;
                    }

                    .r {
                        width: 100%;
                        text-align: right;
                    }

                    .bl {
                        position: relative;
                        bottom: -125px;
                        width: 100%;
                    }

                    .b {
                        position: relative;
                        bottom: -125px;
                        text-align: center;
                        width: 100%;
                    }

                    .br {
                        position: relative;
                        bottom: -125px;
                        text-align: right;
                        width: 100%;
                    }
                }
            }
        }
    }

    @media #{$medium-only} {
        #Slides {
            .slide {
                #Slide2 {
                    background-color: none;
                }

                div.caption-text {
                    margin-top: 200px;
                    padding-left: 20px;
                }
            }
        }
    }

    @media #{$small-only} {
        $img_height: 225px;

        #Slides {
            //height: auto;
            .slide {
                height: auto;
                min-height: 0;
                background-image: none !important;

                #HeroContainer {
                    display: block !important;

                    .hero-wrap {
                        position: static !important;
                    }
                }

                figure.slide-img {
                    height: $img_height;
                    position: static;
                    background-position: center center;
                }

                div.caption-text {
                    //margin-top: 50px;
                    //padding-left: 20px;
                    margin: 0;
                    padding: 25px;
                    background-color: $primary;
                    height: 100%;
                    position: static;

                    a.button {
                        background-color: #FFF;
                        color: $primary;
                    }

                    h1 {
                        font-size: 36px;
                        line-height: 40px;
                    }
                }

                &:before {
                    background: none;
                    display: none;
                }
            }
        }

        ul.slick-dots {
            bottom: auto;
            top: $img_height - 30;

            li {
                button {
                    height: 8px;
                    width: 8px;
                }
            }
        }
    }
}
