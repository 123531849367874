/* ----- Variables ----------------------- */

$raleway: 'Gotham SSm A', 'Gotham SSm B';
$lato: 'Lato', sans-serif;

$play: 'Gotham SSm A', 'Gotham SSm B';

/*
$lato: 'Lato', sans-serif;
$play: 'Playfair Display', serif;
*/

/* ----- Colors -------------------------- */
$black: #222222;
$dark-grey: #404040;
$white: #ffffff;

//Misc
$grey: #949494;
$lightGrey: #f5f5f5;
$darkGrey: #1e2a24;

//Hover
$primaryHover: scale-color($primary, $lightness: -30%);

/* Form Validators Error Color */
$errorRed: #ff0000;

/*$primary: #4cb848;*/
$medPrimary: scale-color($primary, $lightness: -20%);
$darkPrimary: scale-color($primary, $lightness: -40%);


/*$secondary: #cd025f;*/