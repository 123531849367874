.right-off-canvas-menu {
    background: $primary;
    /*.has-dropdown{
        .dropdown{
            display:none;
        }
    }*/
    /*.hover{
        .dropdown{
            display:block;
        }
    }

    .moved{
        .dropdown{
            display:block;
        }
    }*/
    .off-canvas-list {
        li {
            a {
                line-height: 20px;
                padding: 10px 20px;
                font-weight: normal;
                font-size: 16px;
                color: $white;
                background: $primary;
                text-transform: uppercase;
                border-bottom: 1px solid scale-color($primary, $lightness: 4%);
                position: relative;

                &:hover {
                    background: scale-color($primary, $lightness: 10%);
                    color: $white;
                }

                .fa {
                    padding-left: 15px;
                    font-size: 16px;
                    color: scale-color($primary, $lightness: -15%);
                }

                &.search {
                    background: $black;
                    color: $white;

                    .fa {
                        color: $grey;
                    }
                }

                &.buy-tickets {
                    background: $white;
                    color: $black;

                    .fa {
                        color: $primary;
                    }

                    &:hover {
                        color: $black;
                        background: scale-color($white, $lightness: -8%);
                    }
                }
            }

            .dropdown {
                margin: 0;

                li {
                    a {
                        background: scale-color($primary, $lightness: -20%);
                        padding-left: 30px;
                        border-bottom: 1px solid scale-color($primary, $lightness: -26%);

                        &:hover {
                            background: scale-color($primary, $lightness: -30%);
                        }
                    }
                }
            }
        }
    }

    .social-links {
        height: 40px;
        line-height: 40px;
        padding-left: 20px;

        a {
            color: $white;
            font-size: 20px;
            padding-right: 20px;

            &:hover {
                color: scale-color($white, $lightness: -15%);
            }
        }
    }

    .upcoming-events {
        background: $white;
        padding-bottom: 20px;

        label {
            cursor: default;
            background: $lightGrey;
            border-bottom: 1px solid scale-color($lightGrey, $lightness: -8%);
            padding: 10px 0 10px 20px;
            font-size: 16px;
            line-height: 18px;
            text-transform: uppercase;
            margin: 0;
            color: #949494;
        }

        img {
            width: 150px;
            padding-bottom: 10px;
            border-bottom: 1px solid $grey;
            margin: 20px;
        }

        .info {
            padding: 0 0 0 20px;
        }

        h4 {
            margin: 0;
        }

        p {
            margin: 0;
        }
    }

    .logo-container {
        background-color: $white;
        @extend .clearfix;

        img {
            width: 100px;
            height: 57px;
            margin: 10px;
            float: left;
        }

        .event-info, .contact-info {
            float: left;
            position: static;
            width: 170px;
            margin: 10px 0 0 10px;

            p {
                color: $black;
                margin: 0;
                font-size: 13px;
            }

            a {
                color: $black;
            }
        }
    }
}

.move-left .mobile-nav-icon {
    color: $darkPrimary;
}

.mobile-nav-icon {
    height: 75px;
    text-align: center;
    width: 70px;
    color: $dark-grey;
    position: absolute;
    font-size: 25px !important;
    line-height: 25px !important;
    float: right;
    top: 122px;
    left: 180px;
    z-index: 11;

    &:hover {
        color: $darkPrimary;
    }

    span {
        position: absolute;
        bottom: 56px;
        left: 43px;
        line-height: 14px;
        display: block;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        width: 100%;
    }
}